import React from "react";
import { graphql, PageProps } from "gatsby";

import { SEO } from "../components/seo";
import { Layout } from "../components/layout";
import { SiteHeader } from "../components/site-header";
import { FeaturedAlbum } from "../components/featured-album";
import { VideoShowcase } from "../components/video-showcase";
import { PhotoGallery } from "../components/photo-gallery";

export const query = graphql`
  query {
    file(relativePath: { eq: "background.jpg" }) {
      childImageSharp {
        fixed(width: 1200, height: 630) {
          width
          height
          src
        }
      }
    }

    allAlbumsYaml(sort: { fields: release, order: DESC }) {
      nodes {
        name
        release
        cover {
          childImageSharp {
            fixed(width: 500) {
              src
            }
          }
        }
        bandcamp
        spotify
      }
    }
  }
`;

const Page: React.FC<
  PageProps<{
    file: {
      childImageSharp: {
        fixed: { width: number; height: number; src: string };
      };
    };
    allAlbumsYaml: {
      nodes: Album[];
    };
  }>
> = ({ data: { file, allAlbumsYaml } }) => {
  return (
    <Layout>
      <SEO image={file.childImageSharp.fixed} />
      <FeaturedAlbum {...allAlbumsYaml.nodes[0]} />
      <VideoShowcase />
      <PhotoGallery />
    </Layout>
  );
};

export default Page;
