import React from "react";
import cs from "classnames";

export const BorderBox: React.FC<{ className?: string }> = ({
  children,
  className,
}) => (
  <div className={cs("border border-white p-5 sm:p-8", className)}>
    {children}
  </div>
);
