import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { CenterSection } from "./center-section";

export const PhotoGallery: React.FC = () => {
  const {
    file: {
      childGalleryYaml: { upright, landscape },
    },
  } = useStaticQuery<{
    file: {
      childGalleryYaml: {
        upright: { childImageSharp: { fixed: { src: string } } }[];
        landscape: { childImageSharp: { fixed: { src: string } } }[];
      };
    };
  }>(graphql`
    query {
      file(sourceInstanceName: { eq: "gallery" }, extension: { eq: "yml" }) {
        childGalleryYaml {
          upright {
            childImageSharp {
              fixed(width: 720) {
                src
              }
            }
          }
          landscape {
            childImageSharp {
              fixed(width: 1280) {
                src
              }
            }
          }
        }
      }
    }
  `);

  return (
    <CenterSection className="max-w-max">
      <div className="flex flex-wrap">
        {upright.map(({ childImageSharp: { fixed: { src } } }, i) => (
          <div key={i} className="w-1/2 md:w-1/4">
            <img src={src} className="w-full" />
          </div>
        ))}
      </div>
      <div className="flex flex-wrap">
        {landscape.map(({ childImageSharp: { fixed: { src } } }, i) => (
          <div key={i} className="w-full md:w-1/2">
            <img src={src} className="w-full" />
          </div>
        ))}
      </div>
    </CenterSection>
  );
};
