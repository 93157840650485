import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import cs from "classnames";

import { CenterSection } from "../center-section";
import { BorderBox } from "../border-box";

import styles from "./videos.module.css";

export const VideoShowcase: React.FC = () => {
  const [id, setId] = React.useState(0);
  const {
    file: {
      childConfigYaml: { items },
    },
  } = useStaticQuery<{
    file: { childConfigYaml: { items: { childVideosYaml: Video }[] } };
  }>(graphql`
    query {
      file(sourceInstanceName: { eq: "config" }, name: { eq: "videos" }) {
        childConfigYaml {
          items {
            childVideosYaml {
              name
              externalURL
              file {
                publicURL
              }
            }
          }
        }
      }
    }
  `);

  React.useEffect(() => {
    if (!items || !items.length) return;

    const timeout = setTimeout(() => {
      setId((id + 1) % items.length);
    }, 30000);

    return () => {
      clearTimeout(timeout);
    };
  }, [id, items]);

  return (
    <CenterSection>
      <BorderBox className="my-16">
        <div className="flex flex-col md:flex-row">
          <div className="flex-1 mb-10 md:mb-0 md:mr-10">
            <div>
              <div className="mb-4 font-heading text-4xl lg:text-5xl">
                VIDEOS
              </div>
              <div className="text-2xl lg:text-3xl">
                {items.map(({ childVideosYaml: { name } }, i) => (
                  <div key={i} className="mb-1 lg:mb-1.5">
                    <button
                      className={cs(
                        "text-left transition focus:outline-none",
                        id !== i && "opacity-50"
                      )}
                      onMouseEnter={() => {
                        setId(i);
                      }}
                    >
                      {name}
                    </button>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div
              className={cs(
                "aspect-w-1 aspect-h-1 overflow-hidden",
                styles.carousel
              )}
            >
              <div>
                <div className="relative w-full h-full">
                  <div
                    className="absolute top-0 h-full transition-all duration-500 flex"
                    style={{
                      width: `${100 * items.length}%`,
                      left: `-${id * 100}%`,
                    }}
                  >
                    {items.map(
                      (
                        {
                          childVideosYaml: {
                            externalURL,
                            file: { publicURL },
                          },
                        },
                        i
                      ) => (
                        <a
                          key={i}
                          href={externalURL}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="block h-full hover:filter"
                        >
                          <video
                            className="h-full"
                            autoPlay
                            muted
                            loop
                            playsInline
                          >
                            <source src={publicURL} type="video/mp4" />
                          </video>
                        </a>
                      )
                    )}
                  </div>
                  <div
                    className={cs(
                      "absolute inset-0 pointer-events-none transition-all transform origin-center opacity-0",
                      styles.overlay
                    )}
                  >
                    <svg
                      className="w-full h-full opacity-30"
                      width="12"
                      height="14"
                      viewBox="0 0 12 14"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M12 7L9.53674e-07 14L-2.70244e-07 1.04907e-06L12 7Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BorderBox>
    </CenterSection>
  );
};
