import React from "react";

import { CenterSection } from "./center-section";
import { BorderBox } from "./border-box";

export const FeaturedAlbum: React.FC<Album> = ({
  name,
  cover,
  bandcamp,
  spotify,
}) => {
  const externalUrl = bandcamp || spotify;
  return (
    <CenterSection>
      <div className="flex flex-col my-16 md:flex-row">
        <div className="flex flex-col flex-1 justify-center mb-10 md:mb-0 md:mr-10">
          <div className="w-full max-w-lg mx-auto">
            <div className="aspect-w-1 aspect-h-1">
              <div>
                <BorderBox>
                  <img
                    src={cover.childImageSharp.fixed.src}
                    alt={name}
                    className="opject-cover w-full h-full"
                  />
                </BorderBox>
              </div>
            </div>
          </div>
        </div>
        <BorderBox className="flex-1">
          <div className="flex flex-col justify-center items-center h-full text-center">
            <div className="text-lg uppercase mb-6">Neues Album</div>
            <div className="mb-6">
              <div className="font-heading text-4xl leading-tight -mt-2 lg:text-5xl">
                {name}
              </div>
            </div>
            {externalUrl && (
              <a
                href={externalUrl}
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block text-lg uppercase px-8 py-4 border border-white rounded-full transition hover:bg-white hover:text-black"
              >
                Jetzt hören
              </a>
            )}
          </div>
        </BorderBox>
      </div>
    </CenterSection>
  );
};
